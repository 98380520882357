<template>
	<div id="app">
		<router-view></router-view>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				
			}
		},
		mounted() {
			// window.addEventListener('online', this.updateOnlineStatus);
			// window.addEventListener('offline', this.updateOnlineStatus);
		},
		methods: {
			
		}
	}
</script>

<style lang="scss">
	@import url('./assets/css/flex.scss');

	

	.main-box {
		width: 1020px;
		background: #ffffff;
		border-radius: 50px;
		box-shadow: 0px 10px 19px 0px rgba(0, 0, 0, 0.20);
		position: fixed;
		bottom: 30px;
		left: 30px;
		z-index: 99;
	}
	
	.container {
		width: 100%;
		height: 770px;
		background-color: #FFFFFF;
		border-radius: 30px;
	}

	.bottom-gzbh {
		position: fixed;
		bottom: 10px;
		left: 50%;
		transform: translateX(-50%);
		z-index: 199;
		font-size: 28px;
		color: #FFFFFF;
		line-height: 40px;
		width: 100%;
		padding-left: 230px;
	}

	.date-box {
		position: fixed;
		top: 50px;
		right: 50px;
		z-index: 99;
	} 

	.el-loading-mask {
		.el-loading-spinner {
			.el-icon-loading {
				font-size: 60px;
			}

			.el-loading-text {
				font-size: 36px;
			}
		}
	}

	.el-message-box {
		width: 750px !important;

		.el-message-box__header {
			padding: 30px;

			.el-message-box__title {
				font-size: 30px;
			}

			.el-message-box__headerbtn {
				font-size: 30px;
				top: 30px;
				right: 30px;
			}
		}

		.el-message-box__content {
			padding: 30px;
			font-size: 36px;
		}

		.el-message-box__btns {
			padding: 30px;

			.el-button--small {
				padding: 20px 30px;
				font-size: 30px;
				margin-left: 30px !important;
			}
		}
	}

	.el-message {
		width: 600px;
		padding: 25px 25px 25px 30px !important;
		font-size: 30px;
	}

	.el-message .el-message__content {
		font-size: 30px;
	}
	
	.van-toast {
		background-color: rgba(0,0,0,.8) !important;
		font-size: 30px !important;
		padding: 10px 15px !important;
		line-height: 30px !important;
	}

	::-webkit-input-placeholder {
		color: #999999 !important;
	}

	#app {
		height: 100%;
		background-color: #4082FD;
	}

	html,
	body {
		height: 100%;
	}
</style>
