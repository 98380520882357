import axios from 'axios'
import qs from 'querystring'
import { Message } from 'element-ui';

const service = axios.create({
	// baseURL: process.env.VUE_APP_BASE_API,
	baseURL:'https://xx.dz888.shop/',
	timeout: 10000, // 请求超时时间
	withCredentials: false,
	headers: {
		"Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
	}
})
const errorCode = {
	'401': '认证失败，无法访问系统资源',
	'403': '当前操作没有权限',
	'404': '访问资源不存在',
	'default': '系统未知错误，请反馈给管理员'
}

// request拦截器
service.interceptors.request.use(config => {
	let merchantId = localStorage.getItem('merchantId') || ''
	if (merchantId) {
		config.data.merchantId = merchantId
	}
	
	config.data = qs.stringify(config.data)
	return config
}, error => {
	Promise.reject(error)
})

// respone拦截器
service.interceptors.response.use(
	res => {
		// 未设置状态码则默认成功状态
		const code = res.data.code || 200;
		// 获取错误信息
		const msg = errorCode[code] || res.data.msg || errorCode['default']
		if (code === 401) {
			Message({
				message: msg,
				type: 'error'
			})
			return Promise.reject('无效的会话，或者会话已过期，请重新登录。')
		} else if (code === 500) {
			Message({
				message: msg,
				type: 'error'
			})
			return Promise.reject(new Error(msg))
		} else {
			return res.data
		}
	},
	error => {
		// return Promise.reject(error)
		console.log('err' + error)
		let { message } = error;
		if (message == "Network Error") {
			message = "网络异常，请检查网络后重试";
		} else if (message.includes("timeout")) {
			message = "网络异常，请求超时";
		} else if (message.includes("Request failed with status code")) {
			message = "系统接口" + message.substr(message.length - 3) + "异常";
		}
		Message({
			message: message,
			type: 'error',
			duration: 5 * 1000
		})
	}
)

/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 * @returns Promise
 */
export function post(url, params = {}) {
	return new Promise((resolve, reject) => {
		service({
			url,
			method: 'post',
			data: params
		}).then(response => {
			resolve(response)
		}).catch(error => {
			reject(error)
		})
	})
}

/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 * @returns Promise
 */
export function get(url, params = {}) {
	return new Promise((resolve, reject) => {
		service({
			url,
			method: 'get',
			params: params
		}).then(response => {
			resolve(response)
		}).catch(error => {
			reject(error)
		})
	})
}
