import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
	path: '/',
	name: 'home',
	component: () => import('../views/home.vue'),
	redirect: '/index',
	children: [{
		path: '/index',
		name: 'index',
		component: () => import('../views/index.vue'),
	}, {
		path: 'scanCode',
		name: 'scanCode',
		component: () => import('../views/scanCode.vue')
	}, {
		path: 'quCode',
		name: 'quCode',
		component: () => import('../views/quCode.vue')
	}, {
		path: 'boxDoorOpen',
		name: 'boxDoorOpen',
		component: () => import('../views/boxDoorOpen.vue')
	}, {
		path: 'adminList',
		name: 'adminList',
		component: () => import('../views/adminList.vue')
	}, {
		path: 'adminLogin',
		name: 'adminLogin',
		component: () => import('../views/adminLogin.vue')
	}, {
		path: 'quClothes',
		name: 'quClothes',
		component: () => import('../views/quClothes.vue')
	}, {
		path: 'orderList',
		name: 'orderList',
		component: () => import('../views/orderList.vue')
	}, {
		path: 'shoesCode',
		name: 'shoesCode',
		component: () => import('../views/shoesCode.vue')
	}, {
		path: 'xmList',
		name: 'xmList',
		component: () => import('../views/xmList.vue')
	}, {
		path: 'payMoney',
		name: 'payMoney',
		component: () => import('../views/payMoney.vue')
	}, {
		path: 'sysSet',
		name: 'sysSet',
		component: () => import('../views/sysSet.vue')
	}]
}, {
	path: '/advertList',
	name: 'advertList',
	component: () => import('../views/advertList.vue')
}, {
	path: '/noNet',
	name: 'noNet',
	component: () => import('../views/noNet.vue')
}]


const router = new VueRouter({
	mode: 'hash',
	base: process.env.BASE_URL,
	routes
})

export default router
