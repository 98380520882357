import Vue from 'vue'
import './utils/rem.js'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import { post } from './utils/axios.js'
import notClickMore from './utils/notClickMore.js'
import Vant from 'vant'
import 'vant/lib/index.css';
import vueQriously from 'vue-qriously'
import { Toast } from 'vant'


Vue.use(Vant)
Vue.use(ElementUI)
Vue.use(vueQriously)

Toast.setDefaultOptions({ duration: 3000, position: 'bottom' });


Vue.prototype.$post = post
Vue.prototype.$point = notClickMore
Vue.prototype.$api = 'https://xx.dz888.shop/'
Vue.prototype.$version = '1.0.0'
Vue.prototype.$nowTime = () => {
	const date = new Date();
	let year = date.getFullYear();
	let month = date.getMonth() + 1;
	let day = date.getDate();
	let hour = date.getHours();
	let minute = date.getMinutes();
	let second = date.getSeconds()
	
	month = check(month);
	day = check(day);
	hour = check(hour);
	minute = check(minute);
	second = check(second);
	
	function check(i) {
		const num = (i < 10) ? ("0" + i) : i;
		return num;
	}
	
	return year + '-' + month + "-" + day + ' ' + hour + ":" + minute + ':' + second
}

Vue.config.productionTip = false

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
